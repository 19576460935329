@font-face {
    font-family: 'IntelOne Display';
    src: url(/public/fonts/intelone-display-regular.woff2) format('woff2'),
        url(/public/fonts/intelone-display-regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'IntelOne Display';
    src: url(/public/fonts/intelone-display-medium.woff2) format('woff2'),
        url(/public/fonts/intelone-display-medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'IntelOne Display';
    src: url(/public/fonts/intelone-display-light.woff2) format('woff2'),
        url(/public/fonts/intelone-display-light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'IntelOne Display';
    src: url(/public/fonts/intelone-display-bold.woff2) format('woff2'),
        url(/public/fonts/intelone-display-bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IntelClearPro';
    src: local('IntelClearPro_W_Bd'),
        url(/public/fonts/IntelClearPro_W_Bd.woff) format('woff');
}
@font-face {
    font-family: 'intel-one';
    src: url(/public/fonts/intel-clear-latin.woff) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'intel-one';
    src: url(/public/fonts/intelone-display-light.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'intel-one';
    src: url(/public/fonts/intelone-display-regular.woff2) format('woff2'),
        url(/public/fonts/intelone-display-regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'intel-clear';
    src: url(/public/fonts/intel-clear-latin.woff2) format('woff2'),
        url(/public/fonts/intel-clear-latin.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IntelOne Text';
    src: url(/public/fonts/intelone-text-regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'IntelOne Display', 'intel-one', 'intel-clear', 'IntelClearPro',
        -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    * {
        font-family: 'IntelOne Display';
    }

    p {
        font-family: 'IntelOne Text';
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.searchWrapper {
    height: 3vw;
    justify-content: center;
    vertical-align: middle !important;
}
.icon_down_dir {
    position: unset !important;
}
.icon_down_dir:before {
    content: unset !important;
}
.searchBannerColor {
    padding: 4px 10px;
    margin: 6px;
    background: #00a3fb;
    display: inline-flex;

    align-items: center;
    line-height: 0.2;
    color: white;
}
.btn-close {
    width: 0.5rem !important;
    height: 0.5rem !important;
    padding: 0.1rem;
    margin-left: 0.3rem;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
    float: right;
}
.btn-primary.disabled {
    background: #7e969f !important;
}
.recipeCardImageHover {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 30%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    visibility: none;
    opacity: 0;
}
.recipeCardImageHoverContent {
    justify-content: center;
    padding-left: 20%;
}
.pointer {
    cursor: pointer;
}
.footer-logo-rebrand {
    width: 5rem;
}
.noPointer {
    cursor: default;
}
/* modal backdrop fix */
.modal-backdrop.show + .modal.show {
    z-index: 1052 !important;
}
.modal-backdrop.show + .modal.show + .modal-backdrop.show {
    z-index: 1052 !important;
}

div.donut-chart-tooltip,
div.line-chart-tooltip {
    position: absolute;
    text-align: center;
    padding: 0.5rem;
    background: #ffffff;
    color: #313639;
    border: 1px solid #313639;
    border-radius: 5px;
    pointer-events: none;
}

div.line-chart-tooltip {
    background-color: #09244b;
    color: white;
}

div.donut-chart-tooltip p,
div.line-chart-tooltip p {
    font-size: 1rem;
    margin: 0;
}

div.line-chart-tooltip .count {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 700;
    text-align: center;
}

.colorBlock {
    min-width: 1rem;
    max-width: 1rem;
    height: 1rem;
    border-radius: 1px;
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.35rem;
}

.tooltip-p {
    margin: 0.1rem 0.5rem 0.1rem 0.1rem;
    display: inline-block;
}

.mouse-line,
.horizontal-line {
    stroke-dasharray: 2, 2;
}

.clicked {
    border: 2px solid #1565d8;
    margin: -2px;
}
.carousel-control-next-icon {
    background-image: url(/public/aiModelImage/chevron-right.png) !important;
    filter: none !important;
}
.carousel-control-prev-icon {
    background-image: url(/public/aiModelImage/chevron-left.png) !important;
    filter: none !important;
}

.carousel-dark .carousel-indicators [data-bs-target] {
    width: 8px !important;
    height: 8px;
}

.carousel-dark .carousel-indicators [data-bs-target].active {
    background-color: #fa982f !important;
}

.carousel-indicators {
    justify-content: center !important;
    margin-bottom: 0rem !important;
    display: none !important;
}

.navbar-light .navbar-toggler {
    border: none;
}
.navbar-light .navbar-toggler-icon {
    background-image: url(/public/hamburger-menu.png) !important;
    border: none;
    width: 2rem;
    height: 2rem;
}
.navbar-light .navbar-toggler-icon:focus {
    border-color: #0d1b35;
}
.accordion-button,
.accordion-button:not(.collapsed) {
    color: rgb(0, 153, 236) !important;
}

.active-date {
    color: #1a73e8;
}
.swagger-ui .scheme-container {
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding: 30px 20px !important;
    width: 100%;
    background: none !important;
    box-shadow: none !important;
}

.nav-tabs .nav-link {
    border: none !important;
    color: #6a6d75;
    padding: 0 0 !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    /* border-bottom: 4px solid #00c7fd !important;     */
    color: #fff !important;
    background: var(--theme-light-gray-300, rgb(101, 49, 113)) !important;
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0 !important;
}

#processor-dropdown {
    color: black;
}
#mobileNavbar .navbar-toggler-icon {
    background-image: url(/public/ic_menu.svg) !important;
    border: none;
    width: 2rem;
    height: 2rem;
}
.offcanvas-body {
    overflow: auto !important;
    overflow-x: hidden !important;

    /* scroll style-width */
    ::-webkit-scrollbar {
        width: 0.25rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
        border-radius: 20px !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888 !important;
        border-radius: 20px !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
    }
}
#RFS-Label {
    color: #ffffff !important;
}
.carousel-control-next,
.carousel-control-prev {
    width: auto !important;
}
.carousel-indicators {
    display: none !important;
}
.fadeOut {
    animation-name: fadeOut;
}

strong {
    font-weight: 500;
}

@keyframes fadeOut {
    to {
        opacity: 40%;
    }
}
